import React, { createRef } from "react";
import FileSaver from "file-saver";
import InstantMeetButton from "../Components/InstantMeetButton";
import {
  Grid,
  IconButton,
  Typography,
  Stack,
  CircularProgress,
  Popover,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams, Link } from "react-router-dom";
import {
  LinkedIn,
  WhatsApp,
  Instagram,
  Twitter,
  Facebook,
  Mail,
} from "@mui/icons-material";
import { useRootStore } from "../../stores";
import CONFIG from "../../config/config.env";
import { useState, useEffect } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TelegramShareButton,
  PinterestShareButton,
} from "react-share";
import {
  FacebookIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  TelegramIcon,
  PinterestIcon,
} from "react-share";
import ShareIcon from "@mui/icons-material/Share";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircleOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CustomTextField from "../Components/CustomTextField";
import { hotjar } from "react-hotjar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { successToast } from "../../utils/toast";
import Chip from "@mui/material/Chip";
import Iframe from "react-iframe";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReCAPTCHA from "react-google-recaptcha";
import Gravatar from "react-gravatar";
const Profile = (props) => {
  const { user_code, username } = useParams();
  const { authStore } = useRootStore();
  const [user, setUser] = useState();
  const [email, setEmail] = useState();
  const [userVisited, setUserVisited] = useState(true);
  const [contactRecaptcha, setContactRecaptcha] = useState({
    isVerified: false,
  });
  const [mailingRecaptcha, setMailingReCaptcha] = useState({
    isVerified: false,
  });
  const contactRecaptchaRef = createRef();
  const mailingRecaptchaRef = createRef();

  let tags = user?.profile_tags ? user?.profile_tags.split(",") : [];

  let questionTags = user?.question_tags ? user?.question_tags.split(",") : [];

  let mailto = user?.email;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const imgurl = user?.avatar
    ? CONFIG.PUBLIC_URL + user.avatar
    : "/static/img/avatars/default-profile.svg";
  const backgroundUserImgUrl = user?.background_img
    ? CONFIG.PUBLIC_URL + user.background_img
    : "/static/img/avatars/default-profile.svg";

  const [anchorEl, setAnchorEl] = useState(null);

  const showPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hidePopover = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    hotjar.initialize(3086958, 6);
  }, []);

  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? "simple-popover" : undefined;

  const getUser = async () => {
    let isEventRegisteredPath = props.isEvent
      ? `/register/events`
      : `/register`;
    if (username) {
      // changed for keeping the state as isLoggedin = true
      await authStore.resetProfile();
      await authStore.onGetUserByName(username);
      const data = await JSON.parse(localStorage.getItem("userDataProfile"));
      if (data === "undefined" || data === null) {
        window.location.href = isEventRegisteredPath;
      } else {
        setUser(data);
        if (data?.isRedirected && data?.redirect_url) {
          window.location.replace(data.redirect_url);
        }
      }
    }

    if (user_code) {
      await authStore.resetProfile();
      await authStore.onGetUserByCode(user_code);
      const data = await JSON.parse(localStorage.getItem("userDataProfile"));
      if (data === "undefined" || data === null) {
        window.location.href = `${isEventRegisteredPath}/${user_code}`;
      } else {
        if (data?.isDeleted === false) {
          function checkIfValidForEvent(createdAt) {
            var today = new Date().setHours(0, 0, 0, 0);
            var thatDay = createdAt.setHours(0, 0, 0, 0);
            console.log(
              "Inside checkFunction" + today + "<--Today" + thatDay + "Thatday",
            );
            return today === thatDay;
          }

          // const currentTime = new Date(data?.createdAt);
          // const expiryTime = new Date();
          // expiryTime.setDate(expiryTime.getDate() + 1);
          // console.log(currentTime, expiryTime + "");
          // if (currentTime > expiryTime) {
          //   window.location.href = `/register/events`;
          // }
          // function diff_hours(dt2, dt1) {
          //   var diff = (dt2.getTime() - dt1.getTime()) / 1000;
          //   diff /= 60 * 60;
          //   return Math.abs(Math.round(diff));
          // }
          // if (diff_hours(expiryTime, currentTime) >= 24) {
          console.log(props.isEvent + "This is props event");
          if (data?.isRedirected && data?.redirect_url) {
            window.location.replace(data.redirect_url);
          } else {
            if (props.isEvent) {
              if (!checkIfValidForEvent(new Date(data?.createdAt))) {
                window.location.href = `/register/events`;
              } else {
                window.location.href = `/at/event/${data?.username}`;
              }
            } else {
              window.location.href = `/at/${data?.username}`;
            }
          }
        }
        console.log(data?.isDeleted + "this is data isDeleted");
      }
    }
  };
  const shareUrl = window.location;
  const userProfiles = [
    {
      name: "LinkedIn",
      title: "Lets connect on LinkedIn",
      link: user?.linkedin_url,
      color: "primary",
      icon: <LinkedIn />,
    },
    {
      name: "Instagram",
      title: "Check my Instagram",
      link: user?.instagram_url,
      color: "secondary",
      icon: <Instagram />,
    },
    {
      name: "Facebook",
      title: "Add me on Facebook",
      link: user?.facebook_url,
      color: "info",
      icon: <Facebook />,
    },
    {
      name: "WhatsApp",
      title: "Chat with me on WhatsApp",
      link: user?.whatsapp_url,
      color: "success",
      icon: <WhatsApp />,
    },
    {
      name: "Twitter",
      title: "View my Twitter Profile",
      link: user?.twitter_url,
      color: "info",
      icon: <Twitter />,
    },
    {
      name: "Email",
      title: "Drop a mail",
      link: `mailto:${user?.email}`,
      color: "error",
      icon: <Mail />,
    },
  ];

  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    if (user && userVisited) {
      saveUserVisited();
      setUserVisited(false);
    }
  }, [user]);

  async function saveUserVisited() {
    const data = await JSON.parse(localStorage.getItem("userDataProfile"));
    const userdLoggedInData = await JSON.parse(
      localStorage.getItem("userData"),
    );
    if (
      (userdLoggedInData !== "undefined" || userdLoggedInData !== null) &&
      data.id !== userdLoggedInData.id
    ) {
      await authStore.onVisitngUserSave({
        user_profile: data.id,
        who_visited: userdLoggedInData.id,
      });
    }
  }
  function saveVcfFile() {
    let mobileNumber = null;

    if (user?.whatsapp_url) {
      let splitData = user.whatsapp_url.split("/");
      mobileNumber = "+" + splitData[splitData.length - 1];
    }

    var file = new Blob(
      [
        `BEGIN:VCARD
VERSION:3.0
N:;${user?.display_name ? user?.display_name : user?.username};;;
FN:${user?.display_name ? user?.display_name : user?.username}
TITLE:${`ConnektWithMe`}
EMAIL;type=INTERNET;type=pref:${user?.email}
TEL;TYPE=voice,work,pref:${mobileNumber}
ADR;type=WORK;type=pref:;;;${user?.location};;;
END:VCARD
`,
      ],
      { type: "text/vcard;charset=utf-8" },
    );
    FileSaver.saveAs(file, `${user?.username}.vcf`, true);
  }

  const handleClick = async (e) => {
    e.preventDefault();
    console.log("email", email);
    await authStore.onCreateMailingList(username, { email });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const copyToClipboard = () => {
    copy(shareUrl);
    successToast(`Link Copied To Clipboard`);
  };

  const verifyContactReCaptcha = () => {
    console.log("captcha", contactRecaptcha);
    setContactRecaptcha({ isVerified: true });
  };

  const verifyMailingReCaptcha = () => {
    console.log("recaptcha", mailingRecaptcha);
    setMailingReCaptcha({ isVerified: true });
  };

  // Set random image as bg
  const [bgImgURL, setBackgroundImg] = useState("");
  const bgImgAPI = "https://source.unsplash.com/random";

  const fetchImage = async () => {
    const res = await fetch(bgImgAPI);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setBackgroundImg(imageObjectURL);
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "relative",
          background: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "auto",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            width: "100%",
            zIndex: 1,
            filter: user?.background_img ? "blur(10px)" : "blur(50px)",
            position: "absolute",
            height: "100%",
            backgroundImage: `linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(${
              user?.background_img ? backgroundUserImgUrl : bgImgURL
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div
          style={{
            color: "white",
            width: matches ? "100%" : "50%",
            // position:"absolute",
            zIndex: 10,
          }}
        >
          <div
            style={{
              zIndex: 99,
              position: "absolute",
              top: "10%",
              left: matches ? "10%" : "5%",
              transform: "translate(-50%, -50%)",
              color: "white",
            }}
          >
            <ShareIcon
              onClick={showPopover}
              className="share"
              sx={{ cursor: "pointer" }}
            />
            <Popover
              sx={{ backgroundColor: "transparent" }}
              id={id}
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={hidePopover}
              anchorOrigin={{
                vertical: 35,
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div
                style={{
                  padding: "5px",
                  display: "flex",
                  gap: "4px",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                }}
              >
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={32} round={true} class="shareIcon" />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={32} round={true} class="shareIcon" />
                </TwitterShareButton>
                <EmailShareButton url={shareUrl}>
                  <EmailIcon size={32} round={true} class="shareIcon" />
                </EmailShareButton>
                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size={32} round={true} class="shareIcon" />
                </WhatsappShareButton>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={32} round={true} class="shareIcon" />
                </LinkedinShareButton>
                <TelegramShareButton url={shareUrl}>
                  <TelegramIcon size={32} round={true} class="shareIcon" />
                </TelegramShareButton>
                <RedditShareButton url={shareUrl}>
                  <RedditIcon size={32} round={true} class="shareIcon" />
                </RedditShareButton>
                <PinterestShareButton url={shareUrl}>
                  <PinterestIcon size={32} round={true} class="shareIcon" />
                </PinterestShareButton>
                <button onClick={copyToClipboard}>
                  <ContentCopyIcon />
                </button>
              </div>
            </Popover>
          </div>
          {user ? (
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="top"
              pt={8}
            >
              <Grid item xs="auto">
                {user?.avatar ? (
                  <img
                    src={imgurl}
                    style={{
                      height: "85px",
                      width: "85px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Gravatar
                    email={mailto}
                    size={85}
                    style={{ borderRadius: "50%" }}
                  />
                )}
              </Grid>

              <Grid item xs="auto" mt={1}>
                <Typography
                  sx={{
                    typography: { sm: "h6", xs: "h6" },
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {user?.display_name ? user?.display_name : user?.username}
                </Typography>
              </Grid>

              <Grid item xs="auto">
                <Typography
                  sx={{
                    typography: { sm: "subtitle1", xs: "6px" },
                    textAlign: "center",
                  }}
                  color="lightgray"
                >
                  {user?.description}
                </Typography>
              </Grid>

              <Grid item xs="auto">
                <Typography
                  sx={{
                    typography: { sm: "subtitle1", xs: "6px" },
                    textAlign: "center",
                  }}
                  color="lightgray"
                >
                  <Stack direction="row" spacing={1} mt={1}>
                    {tags.map((item) => (
                      <Chip
                        label={item}
                        color="primary"
                        variant="outlined"
                        clickable
                      />
                    ))}
                  </Stack>
                </Typography>
              </Grid>
              {user?.showcaseLink1 && user?.showCaseTitle1 && (
                <Grid item xs="auto">
                  <Typography
                    sx={{
                      typography: { sm: "subtitle1", xs: "6px" },
                      textAlign: "center",
                    }}
                    color="orange"
                  >
                    <a
                      href={
                        user?.showcaseLink1?.includes("https")
                          ? user?.showcaseLink1
                          : "https://" + user?.showcaseLink1
                      }
                    >
                      {user?.showCaseTitle1}
                    </a>
                  </Typography>
                </Grid>
              )}

              {user?.showcaseLink2 && user?.showCaseTitle2 && (
                <Grid item xs="auto">
                  <Typography
                    sx={{
                      typography: { sm: "subtitle1", xs: "6px" },
                      textAlign: "center",
                    }}
                    color="orange"
                  >
                    <a
                      href={
                        user?.showcaseLink2?.includes("https")
                          ? user?.showcaseLink2
                          : "https://" + user?.showcaseLink2
                      }
                    >
                      {user?.showCaseTitle2}
                    </a>
                  </Typography>
                </Grid>
              )}

              <Grid>
                <Typography>
                  <Stack mt={2}>
                    {user?.youtube_link ? (
                      <Iframe
                        url={user?.youtube_link}
                        className="myClassname"
                        display="initial"
                        position="relative"
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                </Typography>
              </Grid>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {userProfiles.map((profile) => {
                  if (profile.link) {
                    return (
                      <>
                        <IconButton
                          size="large"
                          color={profile.color}
                          onClick={() => {
                            window.location.href = profile.link;
                          }}
                        >
                          {profile.icon}
                        </IconButton>
                      </>
                    );
                  }
                })}
                {user.location && (
                  <a href={`https://maps.google.com/?q=${user.location}`}>
                    <PersonPinCircleIcon
                      slot="end"
                      color="error"
                      style={{ fontSize: "2.0rem" }}
                    />
                  </a>
                )}
              </Stack>

              <div
                style={{
                  margin: "10px",
                  visibility: user?.whatsapp_url ? "visible" : "hidden", // toggle contacts visiblility
                }}
              >
                <Accordion
                  style={{ background: "rgb(221 217 217 / 70%)" }}
                  onClick={() => {
                    contactRecaptchaRef.current.execute();
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography>Save Contact Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        display: contactRecaptcha.isVerified ? "none" : "block",
                      }}
                      maxWidth="300px"
                      height={contactRecaptcha.isVerified ? 0 : "auto"}
                      mb={contactRecaptcha.isVerified ? 0 : 2}
                    >
                      This site is protected by reCAPTCHA and the Google
                      <a href="https://policies.google.com/privacy">
                        Privacy Policy
                      </a>{" "}
                      and
                      <a href="https://policies.google.com/terms">
                        &nbsp;Terms of Service
                      </a>{" "}
                      apply.
                    </Typography>
                    <ReCAPTCHA
                      ref={contactRecaptchaRef}
                      sitekey={CONFIG.SITE_KEY}
                      size="invisible"
                      onChange={verifyContactReCaptcha}
                    />
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        disabled={!contactRecaptcha.isVerified}
                        onClick={saveVcfFile}
                        variant="contained"
                        spacing={1}
                      >
                        Save Contact
                      </Button>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </div>

              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    p: 1,
                    m: 1,
                  },
                  justifyContent: "center",
                  alignItems: "center",
                }}
                style={{ cursor: "pointer" }}
              >
                {questionTags.map((item) => (
                  <Paper
                    variant="standard"
                    style={{
                      background: "rgb(221 217 217 / 8%)",
                      color: "white",
                    }}
                    onClick={() =>
                      (window.location =
                        "mailto:" +
                        user?.email +
                        "?Subject=" +
                        CONFIG.APP_NAME +
                        "&body=" +
                        item)
                    }
                  >
                    {item}
                  </Paper>
                ))}
              </Box>

              <Accordion
                style={{
                  background: "rgb(221 217 217 / 70%)",
                  // width:"80%",
                  // margin:"auto",
                  marginTop: "20px",
                }}
                onClick={() => {
                  mailingRecaptchaRef.current.execute();
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography>Subscribe Here</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    style={{
                      display: mailingRecaptcha.isVerified ? "none" : "block",
                    }}
                    maxWidth="300px"
                    mb={contactRecaptcha.isVerified ? 0 : 2}
                  >
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">
                      Privacy Policy
                    </a>{" "}
                    and
                    <a href="https://policies.google.com/terms">
                      &nbsp;Terms of Service
                    </a>{" "}
                    apply.
                  </Typography>
                  <ReCAPTCHA
                    sitekey={CONFIG.SITE_KEY}
                    size="invisible"
                    onChange={verifyMailingReCaptcha}
                    ref={mailingRecaptchaRef}
                  />
                  <div
                    style={{
                      display: "flex",
                      // backgroundColor: "red",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      padding: "5px",
                      gap: "10px",
                    }}
                  >
                    <CustomTextField
                      label={user.mailing_label || "Email"}
                      name="email"
                      type={"email"}
                      placeholder={
                        user?.mailing_placeholder || "Enter Your Email"
                      }
                      value={email}
                      handleChange={handleChange}
                      my={0}
                    ></CustomTextField>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="center"
                      mt={1.5}
                    >
                      <Button
                        disabled={!mailingRecaptcha.isVerified}
                        onClick={handleClick}
                        variant="contained"
                        spacing={1}
                      >
                        {user?.mailing_button || "Subscribe"}
                      </Button>
                    </Stack>
                  </div>
                </AccordionDetails>
              </Accordion>

              {matches && user?.upi_id && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    href={`upi://pay?pa=${user?.upi_id}&amp;cu=INR`}
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    class="upi-pay1"
                  >
                    Pay Me!{" "}
                    <CurrencyRupeeIcon
                      style={{ marginLeft: "5px", color: "lightgreen" }}
                      slot="end"
                    />
                  </Button>
                </Stack>
              )}

              {user?.email && (
                <InstantMeetButton
                  mob={user?.whatsapp_url}
                  meetTime={
                    user?.meeting_time ? user?.meeting_time.split(",") : []
                  }
                  email={user?.email}
                  name={user?.display_name}
                />
              )}

              <Grid item xs="auto" mb={2}>
                <Link to="/">
                  <Typography
                    sx={{
                      typography: { sm: "h4", xs: "h5" },
                    }}
                  >
                    {CONFIG.APP_NAME}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "100vh" }}
              pt={4}
            >
              <CircularProgress />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
