import DividerWithText from "./DividerWithText";
import React from "react";
import ApiCalendar from "react-calendar-with-meet";
import { Button, Typography, Card } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import GoogleCalendar from "../Components/GoogleCalendarEventForm";
import GoogleIcon from "@mui/icons-material/Google";
import styled from "@emotion/styled";
import { errorToast, successToast } from "../../utils/toast";
const config = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(config);

function InstantMeetButton(props) {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const MeetingButton = styled(Button)`
    width: calc(100% - 15%);
    margin: 10px auto;
  `;

  const ParentCardWrapper = styled(Card)`
    background: rgba(255, 255, 255, 0.5);
    width: calc(100% - 10%);
    margin: 20px auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `;
  const [isEventSubmitting, setEventSubmitting] = React.useState(false);
  const [isUserSigned, setUserSigned] = React.useState(false);
  function makeid() {
    var text = "";
    var possible = "0123456789abcdefghijklmnopqrstuv";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  function getEvent(startEventTime, EndEventTime, title, description) {
    let startDate = new Date(startEventTime);
    let endDate = new Date(EndEventTime);
    const result = {
      summary: title,
      time: 60,
      id: makeid() + "1",
      description: description,
      calendarId: "primary",
      start: {
        dateTime: startDate.toISOString(),
        timeZone: "Asia/Kolkata",
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: "Asia/Kolkata",
      },
      attendees: [{ email: props.email }],
      colorId: 1,
      conferenceDataVersion: 1,
      conferenceData: {
        createRequest: {
          requestId: makeid(),
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
          status: {
            statusCode: "success",
          },
        },
      },
    };
    return result;
  }

  function getNextDayOfTheWeek(
    dayName,
    excludeToday = true,
    refDate = new Date(),
    hours,
    minutes
  ) {
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
      dayName.slice(0, 3).toLowerCase()
    );
    if (dayOfWeek < 0) return;
    refDate.setHours(hours, minutes, 0);
    refDate.setDate(
      refDate.getDate() +
        +!!excludeToday +
        ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
    );
    return refDate;
  }

  function createEvent(startEventTime, EndEventTime, title, description) {
    function SendEvent() {
      setEventSubmitting(true);

      let dataObj = getEvent(startEventTime, EndEventTime, title, description);
      console.log(dataObj);
      try {
        if (apiCalendar.sign){

          apiCalendar
            .createEvent(dataObj, "primary", "all")
            .then((result) => {
              successToast("Event created successfully");
              console.log(result);

              setEventSubmitting(false);
            })
            .catch((error) => {
              setEventSubmitting(false);
              if (error?.result?.error?.message) {
                errorToast(error?.result?.error?.message);
              } else {
                errorToast("Please sign in to create an event");
              }
            });
          }else{
            
            setEventSubmitting(false);
            errorToast("Please sign in to create an event");
          }
      } catch (error) {
        setEventSubmitting(false);
        if (error?.result?.error?.message) {
          errorToast(error?.result?.error?.message);
        } else {
          errorToast("Please sign in to create an event");
        }
      }
    }

    SendEvent();
  }

  function setTitleAndDescriptionForInstantEvents(timestamp) {
    let mobileData = props.mob ? props.mob.split("/") : "";
    let mobileNumber = mobileData
      ? `+${mobileData[mobileData.length - 1]}`
      : "";

    let obj = {};
    obj.title = `ConnektWithMe ${apiCalendar.userEmailData} <> ${
      props.name ? props.name : props.email
    }`;
    obj.description = `follow up call after meeting on, ${timestamp}${
      mobileNumber ? ", mobile number: " + mobileNumber : ""
    }
    `;

    return obj;
  }

  return (
    <ParentCardWrapper>
      <MeetingButton
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        variant="contained"
        onClick={() => {
          apiCalendar.handleAuthClick();
          setUserSigned(true);
        }}
      >
        Sign in with {returnGoogleLogo()} to create an event for me
      </MeetingButton>
      {props.meetTime &&
        props.meetTime.length > 0 &&
        props.meetTime.map((el) => {
          let dateArr = el.split(" ");
          let timeArr = dateArr[1].split(":");
          let time = new Date("1970-01-01 " + dateArr[1]);

          return (
            <MeetingButton
              disabled={isEventSubmitting || !isUserSigned}
              variant="contained"
              onClick={() => {
                let date = getNextDayOfTheWeek(
                  dateArr[0],
                  true,
                  new Date(),
                  timeArr[0],
                  0
                );
                let date2 = getNextDayOfTheWeek(
                  dateArr[0],
                  true,
                  new Date(),
                  timeArr[0],
                  30
                );
                let dataObj = setTitleAndDescriptionForInstantEvents(
                  date.toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                );
                createEvent(
                  date,
                  date2,
                  dataObj.title,
                  dataObj.description,
                  true
                );
              }}
            >
              {`Lets discuss further on  ${dateArr[0]} at ${time.toLocaleString(
                "en-US",
                { hour: "numeric", minute: "numeric", hour12: true }
              )}`}
            </MeetingButton>
          );
        })}

      {props.meetTime && props.meetTime.length > 0 && (
        <DividerWithText>Or</DividerWithText>
      )}

      {props?.email && (
        <div
          style={{
            margin: "20px auto",
            paddingBottom: matches ? "0px" : "30px",
          }}
        >
          {" "}
          <GoogleCalendar
            mob={props?.mob}
            email={props?.email}
            name={props?.name}
            isUserSigned={isUserSigned}
          />
        </div>
      )}
    </ParentCardWrapper>
  );
}

function returnGoogleLogo() {
  return (
    <span
      style={{
        margin: "0 5px",
        padding: "3px",
        background: "white",
        borderRadius: "15px",
      }}
    >
      <svg
        viewBox="0 0 24 24"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
          <path
            fill="#4285F4"
            d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
          />
          <path
            fill="#34A853"
            d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
          />
          <path
            fill="#FBBC05"
            d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
          />
          <path
            fill="#EA4335"
            d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
          />
        </g>
      </svg>
    </span>
  );
}

export default InstantMeetButton;
