import { Avatar, Button as MuiButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
} from "@mui/icons-material";
import { spacing } from "@material-ui/system";
import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRootStore } from '../../stores';
import CONFIG from '../../config/config.env';
import ComingSoon from "../../ComingSoon/ComingSoon";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

// const BigAvatar = styled(Avatar)({
//   width: "180px",
//   height: "180px",
//   margin: "0 auto 2px",
//   border:` 1px solid ${grey[500]}`,
//   boxShadow:`0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]}`
// })

const AvatarUpload = () => {
  const [image, _setImage] = useState(null);
  const inputFileRef = createRef(null);
  const { authStore } = useRootStore();

  useEffect(() => {
    fetchAvatar()
  }, [authStore.state]);

  const fetchAvatar = () => {
    try {
      const { userData } = authStore.state;
      if(userData.avatar) {
        const url = CONFIG.PUBLIC_URL + userData.avatar;
        console.log("Image URL ", url);
        fetch(url)
        .then(response => response.blob())
        .then(imageBlob => {
            const imageObjectURL = URL.createObjectURL(imageBlob);
            _setImage(imageObjectURL);
        });
      }
    } catch (e) {
      console.log("Failed to load avatar");
    }
  }

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = async (event) => {
    const newImage = event.target?.files?.[0];
    let formData = new FormData();
    const data = JSON.parse(localStorage.getItem('userData'))
    console.log(newImage);
    if (newImage) {
      setImage(URL.createObjectURL(newImage));
      formData.append('avatar',newImage);
      await authStore.onUploadAvatar(formData, data.id);
      fetchAvatar();
    }
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    const data = JSON.parse(localStorage.getItem('userData'))
    authStore.onUploadAvatar(null, data.id)
    }
  };

  return (
    <CenteredContent>
       <Typography variant="h6" color="white">
            Profile Picture
        </Typography>
      <Avatar
        sx={{width:120, height:120, m:2}}
        src={image || "/static/img/avatars/default-profile.svg"}
        imgProps={{
          style: {
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          },
        }}
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      <label htmlFor="avatar-image-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          mb={2}
          // onClick={handleClick}
        >
          {/* {image ? <DeleteIcon mr={2} /> : 
          } */}
          <UploadIcon mr={2} />Upload
          {/* {image ? "Delete" : } */}
        </Button>
      </label>
    </CenteredContent>
  );
};

export default AvatarUpload;