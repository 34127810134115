import CONFIG from "../config/config.env";
import { getURLWithQueryParams, queryToObject } from "./helperLinkedin";

export const Oauth = async (token) => {
  // Query to exchange our authorization code for an access token
  const LINKEDIN_URL = getURLWithQueryParams(
    "https://cors-bypass-9gv0.onrender.com/https://www.linkedin.com/oauth/v2/accessToken",
    {
      grant_type: "authorization_code",
      code: token,
      client_id: CONFIG.LINKEDIN_CLIENT_ID,
      client_secret: CONFIG.LINKEDIN_CLIENT_SECRET,
      redirect_uri: CONFIG.LINKEDIN_REDIRECT,
    }
  );
  let tok;
  try {
    let resp = await fetch(
      `https://cors-bypass-9gv0.onrender.com/https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${token}&redirect_uri=${CONFIG.LINKEDIN_REDIRECT}&client_id=${CONFIG.LINKEDIN_CLIENT_ID}&client_secret=${CONFIG.LINKEDIN_CLIENT_SECRET}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
    if (resp.ok) {
      tok = await resp.json();
      console.log(tok);
      let { access_token, expires_in } = tok;

      // Query to exchange our token for user infos
      let auth = "Bearer " + access_token;
      console.log(auth);
      let u = {};
      let usr = await fetch(
        "https://cors-bypass-9gv0.onrender.com/https://api.linkedin.com/v2/me",
        {
          method: "GET",
          headers: { Connection: "Keep-Alive", Authorization: auth },
        }
      );
      if (usr.ok) u = await usr.json();
      console.log(u);
      let uemail = {};
      let email = await fetch(
        "https://cors-bypass-9gv0.onrender.com/https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))",
        {
          method: "GET",
          headers: { Connection: "Keep-Alive", Authorization: auth },
        }
      );
      if (email.ok) uemail = await email.json();
      console.log(uemail.elements[0]["handle~"].emailAddress)
      return uemail.elements[0]["handle~"].emailAddress
      // await authStore.onSignUpLinkedin({email:uemail.elements[0]["handle~"].emailAddress});
      // navigate("/dashboard", { replace: true });
    }
  } catch (e) {
    console.log(e, "ERR");
  }
};
