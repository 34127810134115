import ApiRequestService from "./api.service";
import { contentTypes, authHeader } from "./utils";
import { getState } from "./localStorage";

const ApiService = new ApiRequestService();

export const getUser = (token) => {
  const header = { Authorization: token };

  return ApiService.getApi(`/me`, { ...header, ...contentTypes.json }, {});
};

export const getUserByCode = (code) => {
  return ApiService.getApi(`/users/${code}`, {}, {});
};

export const getUserByName = (name) => {
  return ApiService.getApi(`/users/username/${name}`, {}, {});
};

export const putUser = (body, id) => {
  const token = getState("token");
  const header = { Authorization: token };

  return ApiService.putApi(`/users/${id}/`, body, {
    ...header,
    ...contentTypes.json,
  });
};

export const uploadAvatar = (body, id) => {
  const token = getState("token");
  const header = { Authorization: token };

  return ApiService.postApi(`/users/${id}/avatar`, body, {
    ...header,
    ...contentTypes.json,
  });
};
export const uploadBackground = (body, id) => {
  const token = getState("token");
  const header = { Authorization: token };

  return ApiService.postApi(`/users/${id}/background`, body, {
    ...header,
    ...contentTypes.json,
  });
};
export const postVisitingUserData = (body) => {
  return ApiService.postApi(`/create-user-visited`, body, {
    ...contentTypes.json,
  });
};

export const getAllUsers = (token) => {
  const header = { Authorization: token };
  return ApiService.getApi(`/users`, { ...header, ...contentTypes.json }, {});
};

export const deleteUser = (id, token) => {
  const header = { Authorization: token };
  return ApiService.deleteApi(`/users/${id}`, {
    ...header,
    ...contentTypes.json,
  });
};

export const mailingList = (username, body) => {
  return ApiService.postApi(`/users/${username}/subscribe`, body, {});
};

export const meetingListApi = (username, body) => {
  return ApiService.postApi(`/users/${username}/meet`, body, {});
};

export const generateCode = () => {
  return ApiService.getApi(`/code`, { ...contentTypes.json }, {});
}