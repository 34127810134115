import CONFIG from "../config/config.env";

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return `${base}?${query}`;
};

// Url for google redirect
// export const GOOGLE_URL = getURLWithQueryParams(
//   "https://accounts.google.com/o/oauth2/v2/auth",
//   {
//     response_type: "code",
//     client_id: CONFIG.GOOGLE_CLIENT_ID,
//     redirect_uri: CONFIG.GOOGLE_REDIRECT,
//     scope: CONFIG.GOOGLE_SCOPE,
//   }
// );

export const GOOGLE_URL = `${CONFIG.PUBLIC_URL}oauth2/google`;
